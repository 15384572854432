//js libs
import Glide, {Controls, Breakpoints, Swipe, Autoplay} from '@glidejs/glide/dist/glide.modular.esm';

//css
import styles from '../css/app.pcss';

//imgs
import Logo from '../img/ranson-industries.svg';
import ButtonArrow from '../img/button-arrow.png';
import ButtonArrowWhite from '../img/button-arrow__white.png';
import ArrowUpWhite from '../img/arrow-up__white.png';
import Lock from '../img/lock-solid.svg';
import GlideArrow from '../img/glide-arrow.png';

// App main
const main = async () => {
    const glideCss = await import(/* webpackChunkName: "glide" */ '@glidejs/glide/dist/css/glide.core.css');
    // Import our CSS
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');

    // // Create our vue instance
    const vm = new Vue.default({
        el: "#app",
        data: {
            openMobile: false,
            marketInfoFiltersOpen: true
        },
        components: {
            'date-range-picker': () => import(/* webpackChunkName: "market-info-filters" */ '../vue/DateRangePicker.vue'),
            'slide-up-down': () => import(/* webpackChunkName: "market-info-filters" */ 'vue-slide-up-down'),
        },
        methods: {
            toggleMobile(e) {
                e.preventDefault();
                this.openMobile = !this.openMobile;
            },
            slideOpenMarketInfoFilters() {
                if(this.isMobileBreakpoint()){
                    this.marketInfoFiltersOpen = !this.marketInfoFiltersOpen;
                }
            },
            isMobileBreakpoint(){
                return window.matchMedia('screen and (max-width: 576px)').matches;
            }

        },
        mounted() {
            this.marketInfoFiltersOpen = !this.isMobileBreakpoint();
        },
    });

    var logoContainers = document.getElementsByClassName('img__logo');
    [].forEach.call(logoContainers, function(el) {
        el.src = Logo;
    });
    var buttonArrowContainers = document.getElementsByClassName('img__button-arrow');
    [].forEach.call(buttonArrowContainers, function(el) {
        el.src = ButtonArrow;
    });
    var buttonArrowContainers = document.getElementsByClassName('img__button-arrow--white');
    [].forEach.call(buttonArrowContainers, function(el) {
        el.src = ButtonArrowWhite;
    });
    var lockContainers = document.getElementsByClassName('img__lock');
    [].forEach.call(lockContainers, function(el) {
        el.src = Lock;
    });
    var arrowUpWhiteContainers = document.getElementsByClassName('img__arrow-up__white');
    [].forEach.call(arrowUpWhiteContainers, function(el) {
        el.src = ArrowUpWhite;
    });
    var glideArrowsContainers = document.getElementsByClassName('img__glide-arrow');
    [].forEach.call(glideArrowsContainers, function(el) {
        el.src = GlideArrow;
    });
};
// Execute async function
main().then((value) => {

    if(typeof interactiveLinks !== 'undefined') {

        let links = JSON.parse(interactiveLinks);
        let imageEl = document.getElementById("interactive-image");
        let defaultImage = imageEl.dataset.defaultsrc;

        for (let i = 0; i < links.length; i++)
        {
            let el = document.getElementById(links[i].id);
            if(el) {
                // Set params
                el.imageLayer = links[i].image;
                el.linkLayer = links[i].url;
                el.defaultImage = defaultImage;
                el.target = links[i].target;

                // Preload image
                var img = new Image();
                img.src = el.linkLayer;

                el.addEventListener('mouseover', showImage);
                el.addEventListener('mouseout', showDefault);
                el.addEventListener('click', (evt) => { if(window.outerWidth > 768) openLink(evt); });

                el.addEventListener('touchstart', showImage);
                el.addEventListener('touchend', () => { window.location = el.linkLayer; });
            }
        }

        function showImage (evt) {
            imageEl.src = evt.currentTarget.imageLayer;
        }

        function showDefault (evt) {
            imageEl.src = evt.currentTarget.defaultImage;
        }

        function openLink(evt) {
            console.log(evt.currentTarget.target)
            if(evt.currentTarget.target === "_blank") {
                window.open(evt.currentTarget.linkLayer, "_blank");
            } else {
                window.location = evt.currentTarget.linkLayer;
            }
        }

    }

    // ---------- ***    STICKY NAV    *** ----------
    var stickyNav = function(el, stickyNavTop) {
        var scrollTop = window.scrollY;
        if (scrollTop > stickyNavTop) {
            if (!el.classList.contains("has-sticky-nav")) {
                el.classList.add("has-sticky-nav");
            }
        } else if (el.classList.contains("has-sticky-nav")) {
            el.classList.remove("has-sticky-nav");
        }
    };

    stickyNav(document.querySelector(".site-wrapper"), 0);

    window.onscroll = function(e) {
        stickyNav(document.querySelector(".site-wrapper"), 0);
    };

    //glide
    for (const el of document.querySelectorAll(".glide")) {
        if (el.getAttribute('data-slideamount') != null && Number.isInteger(parseInt(el.getAttribute('data-slideamount')))) {
            var glide = new Glide(el, {
                type: 'carousel',
                autoplay: 4000,
                hoverpause: true, // set to false for nonstop rotate
                perView: parseInt(el.getAttribute('data-slideamount'))
            }).mount({Controls, Breakpoints, Swipe, Autoplay});
        } else {
            var glide = new Glide(el, {
                type: 'carousel',
                autoplay: 4000,
                hoverpause: true
            }).mount({Controls, Breakpoints, Swipe, Autoplay});
        }
        var leftBtn = el.parentNode.getElementsByClassName('glide__arrow--left');
        var rightBtn = el.parentNode.getElementsByClassName('glide__arrow--right');
        if (rightBtn.length > 0) {
            rightBtn[0].addEventListener('click', function(e) {
                glide.go('>');
            });
        }
        if (leftBtn.length > 0) {
            leftBtn[0].addEventListener('click', function(e) {
                glide.go('<');
            });
        }
    }

    //glide master-slave logic
    let nextSlideTimer;

    function changeMasterSlide(masterGlide, direction) {
        nextSlideTimer = setTimeout(function() {
            changeMasterSlide(masterGlide, direction);
        }, 6000);
        if (direction == 'next') {
            masterGlide.go('>');
        } else if (direction == 'prev') {
            masterGlide.go('<');
        }

        var controls = document.querySelectorAll('.master-glide__control');
        controls.forEach(node => {
            node.classList.remove('active');
        });
        var activeControl = document.querySelectorAll('[data-slide="' + masterGlide.index + '"]');
        activeControl[0].classList.add('active');
    }

    //product range slider
    for (const el of document.querySelectorAll(".master-glide")) {
        var masterGlide = new Glide(el).mount({Controls, Breakpoints, Swipe});
        var controls = document.querySelectorAll('.master-glide__control')
        var leftBtn = el.parentNode.getElementsByClassName('master-glide__arrow--left');
        var rightBtn = el.parentNode.getElementsByClassName('master-glide__arrow--right');
        if (rightBtn.length > 0) {
            rightBtn[0].addEventListener('click', function(e) {
                clearTimeout(nextSlideTimer);
                changeMasterSlide(masterGlide, "next");
            });
        }
        if (leftBtn.length > 0) {
            leftBtn[0].addEventListener('click', function(e) {
                clearTimeout(nextSlideTimer);
                changeMasterSlide(masterGlide, "prev");
            });
        }
        nextSlideTimer = setTimeout(function() {
            changeMasterSlide(masterGlide, "next");
        }, 6000);
        for (var i = 0; i < controls.length; i++) {
            controls[i].masterGlide = masterGlide;
            controls[0].classList.add('active');
            controls[i].addEventListener('click', function(e) {
                e.preventDefault();
                clearTimeout(nextSlideTimer);
                var slideIndex = e.currentTarget.getAttribute('data-slide');
                masterGlide.go('=' + slideIndex);
                controls.forEach(node => {
                    node.classList.remove('active');
                });
                e.currentTarget.classList.add('active');
                scrollIt(
                    document.querySelector('.product-range'),
                    300,
                    'easeOutQuad'
                );
                nextSlideTimer = setTimeout(function() {
                    changeMasterSlide(masterGlide, "next");
                }, 6000);
            });
        }
    }

    //footer scroller
    document.getElementById('top-scroller').addEventListener('click', function(e) {
        e.preventDefault();
        scrollIt(
            document.querySelector('body'),
            300,
            'easeOutQuad'
        );
    });

    //tabs
    if (document.querySelectorAll('.contact-info').length > 0) {
        var tabControls = document.querySelectorAll(".contact-info__controls .button");

        function myTabClicks(e) {
            for (var i = 0; i < tabControls.length; i++) {
                tabControls[i].classList.remove("active");
            }
            var clickedTab = e.currentTarget;
            clickedTab.classList.add("active");
            e.preventDefault();
            var myContentPanes = document.querySelectorAll(".contact-info__pane");
            for (i = 0; i < myContentPanes.length; i++) {
                myContentPanes[i].classList.remove("active");
            }
            var activePane = document.getElementById(e.currentTarget.getAttribute('data-url'));
            activePane.classList.add("active");
            scrollIt(
                activePane,
                300,
                'easeOutQuad'
            );
        }

        for (i = 0; i < tabControls.length; i++) {
            tabControls[i].addEventListener("click", myTabClicks)
        }
    }


    function scrollIt(destination, duration = 200, easing = 'linear', callback = false) {

        const easings = {
            linear(t) {
                return t;
            },
            easeInQuad(t) {
                return t * t;
            },
            easeOutQuad(t) {
                return t * (2 - t);
            },
            easeInOutQuad(t) {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            },
            easeInCubic(t) {
                return t * t * t;
            },
            easeOutCubic(t) {
                return (--t) * t * t + 1;
            },
            easeInOutCubic(t) {
                return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            },
            easeInQuart(t) {
                return t * t * t * t;
            },
            easeOutQuart(t) {
                return 1 - (--t) * t * t * t;
            },
            easeInOutQuart(t) {
                return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
            },
            easeInQuint(t) {
                return t * t * t * t * t;
            },
            easeOutQuint(t) {
                return 1 + (--t) * t * t * t * t;
            },
            easeInOutQuint(t) {
                return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
            }
        };

        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
        let destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

        destinationOffsetToScroll = Math.max(destinationOffsetToScroll - 86, 0); //offset header

        if ('requestAnimationFrame' in window === false) {
            window.scroll(0, destinationOffsetToScroll);
            if (callback) {
                callback();
            }
            return;
        }

        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, ((now - startTime) / duration));
            const timeFunction = easings[easing](time);
            window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

            if (window.pageYOffset === destinationOffsetToScroll) {
                if (callback) {
                    callback();
                }
                return;
            }

            requestAnimationFrame(scroll);
        }

        scroll();
    }


});
